@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Overlock:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
#root,
body {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6, 
p,
a,
li,
em {
  color: #fff;
  margin: 0;
  font-family: overlock, sans-serif;
}
.Background_background__jnS8b {
    background-color: #101728;
    width: 100%;
    min-height: 100%;
}
.parallax-container {
    height: 600px;
    /* Altura desejada para o efeito de paralaxe */
    overflow: hidden;
    position: relative;
}

.bg-perfil {
    background-image: url(/static/media/bg-perfil.7ea47017.webp);
    background-size: cover;
    background-position: center;
}

.video-background {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0;
    top: 0;
    left: 0;
    background-image: url(/static/media/perfilfig.e04163f6.gif);
    /* Substitua com a imagem de capa do vídeo */
    background-size: cover;
    background-position: center;
}

.content {
    position: relative;
    z-index: 1;
    color: #fff;
    /* Cor do texto para garantir visibilidade */
    text-align: center;
    /* Centraliza o conteúdo */
    padding: 20px;
}


/* */
.container-perfil {
    position: absolute;
    top: 33%;
    right: 30%;
    padding: 57px;
    background: rgb(93 90 90 / 50%);
    border-radius: 20px;
}

h4 {
    font-size: 25px;
}

h3 {
    color: rgb(214, 201, 17) !important;
    font-size: 35px;
    margin: 17px 0 !important;
}

h1 {
    font-size: 21px;
}

.container-line {
    overflow: hidden;
}

.container-line span {
    background-color: rgb(9, 63, 124);
    height: 74PX;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-about {
    width: 100%;
    margin: 0;
    background-color: #fff;
    color: black;
    padding: 40px;
}

.content-about p,
.content-about li {
    max-width: 857px;
    margin: 0 auto 30px;
}

.container-line span {
    font-weight: bold;
    letter-spacing: 0.2em;
    font-size: 24px;

}

.container-line p,
.container-line li,
.container-line a {
    color: black;
}

.container-line li {
    padding-left: 50px;
    position: relative;
    left: 20px;
}

.content-experiencias {
    display: flex;
    justify-content: space-between;
    max-width: 857px;
    margin: 0 auto 60px;
    position: relative;
}

.content-experiencias .seccond-content {
    width: 40%;
    margin: 0 5%;
}

.content-experiencias b {
    letter-spacing: 0.25em;

    font-weight: bold;
    line-height: 1.8em;

    font-size: 18px;
}

.content-experiencias p {
    color: rgb(9, 63, 124);
}

.imgCursos {
    max-width: 200px;
}

.container {
    max-width: 857px;
    margin: 0 AUTO;
    display: flex;
    justify-content: space-between;
}

.container div {
    width: 45%;
}

.line-vertical {
    border-top: 3px solid rgb(173, 172, 172);
    opacity: 0.3;
    transform: rotate(90deg);
    position: relative;
    right: -595px;
    width: 1155px;
    top: -568px;
    height: 5px;
    --comp-rotate-z: 90deg;
    transform-origin: center 0.5px;
}

.arrow-sgv {
    position: absolute;
    width: 20px;
    height: 30px;
    transform: rotate(90deg);
    left: 347px;
    z-index: 9;
    top: 0;
}

.funcao {
    letter-spacing: 0.2em;
}

@media(max-width: 992px) {
    .content-about {
        padding: 40px 0;
    }

    .content-experiencias {
        flex-direction: column;
        align-items: center;
    }

    .content-experiencias p {
        text-align: center;
    }

    .seccond-content p {
        text-align: start;
    }

    .content-about p {
        max-width: 300px;
    }

    .container-line li {
        left: 0;
    }

    .content-experiencias svg {
        display: none;
    }

    .content-about .container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.container-portifolio {
    max-width: 857px;
    margin: 0 auto;
    display: flex;
}

.container-portifolio p {
    color: black;
}

.container-portifolio h5 {
    color: rgb(13, 94, 186);
    font-size: 18px;
    margin: 5px 0 10px;
}

.container-portifolio img {
    max-width: 230px
}

.box-portifolio {
    max-width: 300px;
    padding: 20px;
}

@media(max-width: 992px) {
    .container-portifolio {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.Header_logo__10UYw {
    max-width: 100px;
}

header {
    padding: 40px;
    position: fixed;
    z-index: 999;
    width: 100%;
    background-color: white;
}

header div {
    margin-left: 30px;
}

header div p {
    max-width: 520px;
    margin-top: 10px;
    color: black;
}

header a {
    color: black;
}

.Header_linkWhats__1Lau3 {
    text-decoration: underline;
    margin-top: 40px;
    display: block;
}

.Header_containerSocial__379yL {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 140px;
}

.Header_containerSocial__379yL div{
    margin: 0;
}

.Header_socialIcons__dNRdj {
    display: flex;
}

.Header_socialIcons__dNRdj img {
    width: 50px;
    position: relative;
    bottom: 1px;
    right: 1px;
}

.Header_socialIcons__dNRdj a {
    background-color: white;
    border-radius: 60px;
    width: 48px;
    height: 49PX;
    position: relative;
    margin: 20px 10px 0;
}

.Header_containerSocial__379yL p {
    font-weight: bold;
}

@media(max-width: 668px) {
    .Header_logo__10UYw{
        margin: 0 0 40px 0px;
    }
    header div {
        margin-left: 0px;
    }
}
.ContainerSmall_containerSmall__1jD0F {
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.ContainerLarge_containerLarge__1ryQJ {
    max-width: 1900px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
}

@media(max-width: 668px) {
    .ContainerLarge_containerLarge__1ryQJ{
        justify-content: flex-start;
    }
}
.PageProj_containerProj__WzpGZ {
    margin: 0 auto;
}

.PageProj_container__10kuB {
    max-width: 800px;
    margin: 60px auto;
}

.PageProj_container__10kuB h1 {
    text-align: center;
    margin-bottom: 40px;
}

.PageProj_container__10kuB p {
    padding-bottom: 40px;
}

.PageProj_containerProj__WzpGZ {
    padding-bottom: 60px;
}

.PageProj_ImageUnique__1Abhv {
    max-width: 800px;
    margin: 0 auto 40px;
    display: block;
}

.PageProj_voltar__3-WiL {
    color: white;
    text-align: end;
    font-weight: bold;
    text-decoration: underline;
    display: block;
    position: relative;
    bottom: 30px;
    padding-right: 10px;
}

.PageProj_containerProj__WzpGZ a {
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin: 0 auto;
}

@media(max-width: 992px) {
    .PageProj_ImageUnique__1Abhv {
        max-width: 100%
    }

    .PageProj_container__10kuB p {
        padding: 0 40px 40px 40px;
    }
}
