.containerProj {
    margin: 0 auto;
}

.container {
    max-width: 800px;
    margin: 60px auto;
}

.container h1 {
    text-align: center;
    margin-bottom: 40px;
}

.container p {
    padding-bottom: 40px;
}

.containerProj {
    padding-bottom: 60px;
}

.ImageUnique {
    max-width: 800px;
    margin: 0 auto 40px;
    display: block;
}

.voltar {
    color: white;
    text-align: end;
    font-weight: bold;
    text-decoration: underline;
    display: block;
    position: relative;
    bottom: 30px;
    padding-right: 10px;
}

.containerProj a {
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin: 0 auto;
}

@media(max-width: 992px) {
    .ImageUnique {
        max-width: 100%
    }

    .container p {
        padding: 0 40px 40px 40px;
    }
}