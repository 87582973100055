@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overlock:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');

html,
#root,
body {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6, 
p,
a,
li,
em {
  color: #fff;
  margin: 0;
  font-family: overlock, sans-serif;
}