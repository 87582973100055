.parallax-container {
    height: 600px;
    /* Altura desejada para o efeito de paralaxe */
    overflow: hidden;
    position: relative;
}

.bg-perfil {
    background-image: url('../../images/bg-perfil.webp');
    background-size: cover;
    background-position: center;
}

.video-background {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0;
    top: 0;
    left: 0;
    background-image: url('../../images/perfilfig.gif');
    /* Substitua com a imagem de capa do vídeo */
    background-size: cover;
    background-position: center;
}

.content {
    position: relative;
    z-index: 1;
    color: #fff;
    /* Cor do texto para garantir visibilidade */
    text-align: center;
    /* Centraliza o conteúdo */
    padding: 20px;
}


/* */
.container-perfil {
    position: absolute;
    top: 33%;
    right: 30%;
    padding: 57px;
    background: rgb(93 90 90 / 50%);
    border-radius: 20px;
}

h4 {
    font-size: 25px;
}

h3 {
    color: rgb(214, 201, 17) !important;
    font-size: 35px;
    margin: 17px 0 !important;
}

h1 {
    font-size: 21px;
}

.container-line {
    overflow: hidden;
}

.container-line span {
    background-color: rgb(9, 63, 124);
    height: 74PX;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-about {
    width: 100%;
    margin: 0;
    background-color: #fff;
    color: black;
    padding: 40px;
}

.content-about p,
.content-about li {
    max-width: 857px;
    margin: 0 auto 30px;
}

.container-line span {
    font-weight: bold;
    letter-spacing: 0.2em;
    font-size: 24px;

}

.container-line p,
.container-line li,
.container-line a {
    color: black;
}

.container-line li {
    padding-left: 50px;
    position: relative;
    left: 20px;
}

.content-experiencias {
    display: flex;
    justify-content: space-between;
    max-width: 857px;
    margin: 0 auto 60px;
    position: relative;
}

.content-experiencias .seccond-content {
    width: 40%;
    margin: 0 5%;
}

.content-experiencias b {
    letter-spacing: 0.25em;

    font-weight: bold;
    line-height: 1.8em;

    font-size: 18px;
}

.content-experiencias p {
    color: rgb(9, 63, 124);
}

.imgCursos {
    max-width: 200px;
}

.container {
    max-width: 857px;
    margin: 0 AUTO;
    display: flex;
    justify-content: space-between;
}

.container div {
    width: 45%;
}

.line-vertical {
    border-top: 3px solid rgb(173, 172, 172);
    opacity: 0.3;
    transform: rotate(90deg);
    position: relative;
    right: -595px;
    width: 1155px;
    top: -568px;
    height: 5px;
    --comp-rotate-z: 90deg;
    transform-origin: center 0.5px;
}

.arrow-sgv {
    position: absolute;
    width: 20px;
    height: 30px;
    transform: rotate(90deg);
    left: 347px;
    z-index: 9;
    top: 0;
}

.funcao {
    letter-spacing: 0.2em;
}

@media(max-width: 992px) {
    .content-about {
        padding: 40px 0;
    }

    .content-experiencias {
        flex-direction: column;
        align-items: center;
    }

    .content-experiencias p {
        text-align: center;
    }

    .seccond-content p {
        text-align: start;
    }

    .content-about p {
        max-width: 300px;
    }

    .container-line li {
        left: 0;
    }

    .content-experiencias svg {
        display: none;
    }

    .content-about .container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}