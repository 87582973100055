.container-portifolio {
    max-width: 857px;
    margin: 0 auto;
    display: flex;
}

.container-portifolio p {
    color: black;
}

.container-portifolio h5 {
    color: rgb(13, 94, 186);
    font-size: 18px;
    margin: 5px 0 10px;
}

.container-portifolio img {
    max-width: 230px
}

.box-portifolio {
    max-width: 300px;
    padding: 20px;
}

@media(max-width: 992px) {
    .container-portifolio {
        flex-wrap: wrap;
        justify-content: center;
    }
}