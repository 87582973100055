.logo {
    max-width: 100px;
}

header {
    padding: 40px;
    position: fixed;
    z-index: 999;
    width: 100%;
    background-color: white;
}

header div {
    margin-left: 30px;
}

header div p {
    max-width: 520px;
    margin-top: 10px;
    color: black;
}

header a {
    color: black;
}

.linkWhats {
    text-decoration: underline;
    margin-top: 40px;
    display: block;
}

.containerSocial {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 140px;
}

.containerSocial div{
    margin: 0;
}

.socialIcons {
    display: flex;
}

.socialIcons img {
    width: 50px;
    position: relative;
    bottom: 1px;
    right: 1px;
}

.socialIcons a {
    background-color: white;
    border-radius: 60px;
    width: 48px;
    height: 49PX;
    position: relative;
    margin: 20px 10px 0;
}

.containerSocial p {
    font-weight: bold;
}

@media(max-width: 668px) {
    .logo{
        margin: 0 0 40px 0px;
    }
    header div {
        margin-left: 0px;
    }
}